.container{
    height: 100vh;
    width:100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
}

.imageOverlay{
    position:absolute;
    background:rgba(17, 24, 45, 0.3);
    width:100%;
    height:100%;
}
.content{
    display:flex;
    -webkit-box-align: center;
    align-items:center;
    color:#fff;
    margin:auto;
    justify-content: center;
    -webkit-box-pack: center;
    height: 100%;
    opacity: 0.96;
}
.contentText{
    -webkit-box-flex: 0;
    width:90%;
    flex:none;
}

.scrollDown{
    width:100%;
    position:absolute;
    bottom:0;
}
.scrollDownLink{
    display: block;
    color:#fff;
    width:100%;
    height:56px;
    text-align:center;
    background-image: url('/images/arrow-down-white.png');
    background-repeat  : no-repeat;
    background-position: center;
}
.scrollDownLink:hover{
    background-image: url('/images/arrow-down-white-hover.png');
}
.marginBottom{
    padding-bottom:48px;
}
@media only screen and (min-width: 600px) {
    .contentText{width:60%}
    h1.cover { font-size: 64px; }
    h2.cover {font-size: 28px; }
}
